export const defaultProductData = {
  parent_id: '',
  parent_sku: '',
  market: '',
  title: '',
  short_description: '',
  long_description: '',
  item_category: '',
  brand: '',
  item_collection: '',
  currency: '',
  gender: '',
  age_group: '',
  default_url: '',
  tags: '',
  base_price: 0,
  variants_selection_order: [],
  variants: [],
  retail_price: 0,
  sale_price: 0,
  createdAt: '',
  updatedAt: '',
  id: '',
};
