import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import type {} from '@redux-devtools/extension';
import { ProductState } from '../interfaces/components';
import { defaultProductData } from '../defaultData';

interface GlobalState {
  showOverlayTrue: boolean;
  bannerClosed: boolean;
  searchBarText: string;
  setSearchBarText: (text: string) => void;
  setBannerClosed: (state: boolean) => void;
  setShowOverlayTrue: (state: boolean) => void;
  productTrailStartedId: string;
  productDrawerLoading: boolean;
  productDrawerData: ProductState;
  setProductDrawerLoading: (state: boolean) => void;
  setProductDrawerData: (state: ProductState) => void;
  setProductIdTrailStartedId: (state: string) => void;
}

export const useGlobalStore = create<GlobalState>()(
  devtools(
    persist(
      (set) => ({
        showOverlayTrue: true,
        bannerClosed: false,
        searchBarText: '',
        productTrailStartedId: '',
        productDrawerLoading: false,
        productDrawerData: {
          data: defaultProductData,
          active: false,
          productId: '',
          fromRecommendation: false,
        },
        setSearchBarText: (text: string) => set(() => ({ searchBarText: text })),
        setProductIdTrailStartedId: (text: string) => set(() => ({ productTrailStartedId: text })),
        setBannerClosed: (state: boolean) => set(() => ({ bannerClosed: state })),
        setShowOverlayTrue: (state: boolean) => set(() => ({ showOverlayTrue: state })),
        setProductDrawerLoading: (state: boolean) => set(() => ({ productDrawerLoading: state })),
        setProductDrawerData: (state: ProductState) => set(() => ({ productDrawerData: state })),
      }),
      {
        name: 'global-storage',
        partialize: (state) => ({ bannerClosed: state.bannerClosed, showOverlayTrue: state.showOverlayTrue }),
      },
    ),
  ),
);
