import React from 'react';
import { Icon, IconProps } from '@chakra-ui/icons';

export const Trash = (props: IconProps) => (
  <Icon width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.833 4.41187H2.83301M6.83301 7.41187V11.4119M9.83301 7.41187V11.4119M12.833 4.41187V13.9119C12.833 14.0445 12.7803 14.1717 12.6866 14.2654C12.5928 14.3592 12.4656 14.4119 12.333 14.4119H4.33301C4.2004 14.4119 4.07322 14.3592 3.97945 14.2654C3.88569 14.1717 3.83301 14.0445 3.83301 13.9119V4.41187M10.833 4.41187V3.41187C10.833 3.14665 10.7277 2.89229 10.5401 2.70476C10.3526 2.51722 10.0982 2.41187 9.83301 2.41187H6.83301C6.56779 2.41187 6.31344 2.51722 6.1259 2.70476C5.93836 2.89229 5.83301 3.14665 5.83301 3.41187V4.41187"
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
