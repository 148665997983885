import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import type {} from '@redux-devtools/extension';
import { FeedItem } from '../interfaces/components';

interface FeedState {
  gameViewerActive: boolean;
  selectedFeedItem: FeedItem;
  feedItems: FeedItem[];
  setGameViewerActive: (state: boolean) => void;
  setSelectedFeedItem: (feedItem: FeedItem) => void;
  setFeedItems: (feedItems: FeedItem[]) => void;
  addInfiniteData: (feedItems: FeedItem[]) => void;
}

const defaultSelectedFeedItem: FeedItem = {
  name: '',
  thumbnailUrl: '',
  type: 'game',
  additionalIdentifier: '',
  additionalUrl: '',
};

export const useFeedStore = create<FeedState>()(
  devtools(
    // persist(
    (set) => ({
      gameViewerActive: false,
      selectedFeedItem: defaultSelectedFeedItem,
      feedItems: [],
      setGameViewerActive: (state: boolean) => set(() => ({ gameViewerActive: state })),
      setSelectedFeedItem: (feedItem: FeedItem) => set(() => ({ selectedFeedItem: feedItem })),
      setFeedItems: (feedItems: FeedItem[]) => set(() => ({ feedItems: feedItems })),
      addInfiniteData: (feedItems: FeedItem[]) =>
        set((state) => ({
          feedItems: [...state.feedItems, ...feedItems],
        })),
    }),
    {
      name: 'Feed-storage',
    },
    // ),
  ),
);
