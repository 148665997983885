import React from 'react';
import { Icon, IconProps } from '@chakra-ui/icons';

export const CartPlus = (props: IconProps) => (
  <Icon width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.1367 17.025H8.42098C8.08371 17.0249 7.75716 16.9065 7.49825 16.6904C7.23935 16.4743 7.06451 16.1742 7.00422 15.8424L4.70806 3.2163C4.67792 3.0504 4.5905 2.90034 4.46105 2.79228C4.33159 2.68423 4.16832 2.62502 3.99968 2.625H2.375M6.56348 13.425H17.8642C18.2015 13.4249 18.5281 13.3065 18.787 13.0904C19.0459 12.8743 19.2207 12.5742 19.281 12.2424L20.3755 6.22498M12.5001 9.79692V7.5469M12.5001 7.5469V5.29688M12.5001 7.5469H10.25M12.5001 7.5469H14.7501M10.2947 18.825C10.2947 19.8191 9.48876 20.625 8.49454 20.625C7.50031 20.625 6.69434 19.8191 6.69434 18.825C6.69434 17.8309 7.50031 17.025 8.49454 17.025C9.48876 17.025 10.2947 17.8309 10.2947 18.825ZM18.9344 18.825C18.9344 19.8191 18.1284 20.625 17.1342 20.625C16.14 20.625 15.334 19.8191 15.334 18.825C15.334 17.8309 16.14 17.025 17.1342 17.025C18.1284 17.025 18.9344 17.8309 18.9344 18.825Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
