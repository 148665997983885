import React from 'react';
import { Icon, IconProps } from '@chakra-ui/icons';

export const Cart = (props: IconProps) => (
  <Icon width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21.1818 20.6999H9.56115C9.11147 20.6998 8.67607 20.542 8.33087 20.2538C7.98567 19.9657 7.75256 19.5655 7.67218 19.1231L4.61069 2.2884C4.5705 2.0672 4.45394 1.86712 4.28134 1.72304C4.10874 1.57897 3.89104 1.50003 3.6662 1.5H1.5M7.08549 15.9001H22.1529C22.6026 15.9 23.038 15.7422 23.3832 15.454C23.7284 15.1659 23.9615 14.7657 24.0419 14.3233L25.5012 6.30014M12.0588 23.1C12.0588 24.4255 10.9842 25.5 9.65856 25.5C8.33296 25.5 7.25834 24.4255 7.25834 23.1C7.25834 21.7745 8.33296 20.7 9.65856 20.7C10.9842 20.7 12.0588 21.7745 12.0588 23.1ZM23.5799 23.1C23.5799 24.4255 22.5052 25.5 21.1796 25.5C19.854 25.5 18.7794 24.4255 18.7794 23.1C18.7794 21.7745 19.854 20.7 21.1796 20.7C22.5052 20.7 23.5799 21.7745 23.5799 23.1Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
