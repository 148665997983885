import { Box } from '@chakra-ui/react';
import { SwatchProps } from '../../interfaces/components';
import React from 'react';

function Swatch({ active = false, transition, colorName, available, onSwatchClick }: SwatchProps) {
  return (
    <Box
      flex={['0 0 auto', '0 0 auto', '0 0 auto', '0 0 auto', '0 0 auto']}
      backgroundPosition="center"
      backgroundSize="cover"
      _notLast={{ marginRight: '10px' }}
      border={
        active
          ? '2px solid #FFFFFF'
          : available
          ? `2px solid rgba(255, 255, 255, 0.5)`
          : '2px solid rgba(255, 255, 255, 0.5)'
      }
      borderRadius="50%"
      _hover={{
        border: available ? '2px solid  #FFFFFF' : '2px solid rgba(255, 255, 255, 0.5)',
      }}
      width={['44px', '44px', '50px', '50px', '50px']}
      height={['44px', '44px', '50px', '50px', '50px']}
      position="relative"
      overflow="hidden"
      onClick={onSwatchClick}
      pointerEvents={available ? 'all' : 'none'}
      cursor={available ? 'pointer' : 'unset'}
      transition={transition}
    >
      {colorName?.length > 0 && (
        <Box
          backgroundImage={colorName}
          backgroundSize={'contain'}
          backgroundPosition={'center'}
          h="100%"
          width="100%"
          borderRadius="50%"
        ></Box>
      )}
      {!available && (
        <Box top="0" left="0" position="absolute" bg="rgba(255, 255, 255, 0.2)" width="100%" height="100%" />
      )}

      {!available && (
        <Box
          width={['calc(1.414 * 44px)', 'calc(1.414 * 44px)', 'calc(1.414 * 50px)', 'calc(1.414 * 50px)']}
          transform="rotate(-45deg)"
          transformOrigin="top right"
          borderTop="1px solid rgb(255, 255, 255)"
          position="absolute"
          top="-2px"
          right="-1px"
          boxSizing="border-box"
        ></Box>
      )}
    </Box>
  );
}

export default Swatch;
