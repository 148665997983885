export const ARPCUrls = {
  '1172288913': 'AR/index.html?name=WalkerEdisonSideboard',
  '2795761532': 'AR/index.html?name=Oaklee6DrawerDresser',
  '5325854900': 'AR/index.html?name=GuyiCloudCoffeeTable',
  '532121642': 'AR/index.html?name=DecModeAccentTable',
  '2001343371': 'AR/index.html?name=ChesterfieldSofa',
  '384053648': 'AR/index.html?name=bigjoejoeybeanbagchair',
  '521928725': 'AR/index.html?name=mainstaysfauxfurplush',
  '5105358640': 'AR/index.html?name=urbanshoppurpleflowerdecorativepillow',
  '5261193145': 'AR/index.html?name=betterhomegardensmiraswivelchair',
  '42338382': 'AR/index.html?name=mainstaysrosette',
  '1670734640': 'AR/index.html?name=mainstays',
  '5113233021': 'AR/index.html?name=pacman',
  '674371865': 'AR/index.html?name=zenstyle',
  '5587806211': 'AR/index.html?name=durablemarsbookcase',
  '606463417': 'AR/index.html?name=mainstayssquare',
  '6053161268': 'AR/index.html?name=bigjoejoey',
  '5305297350': 'AR/index.html?name=urbanshopdiscoballdecorativepillow',
  '1528649331': 'AR/index.html?name=betterhomesgardenstabletoppictureframe',
  '3977610044': 'AR/index.html?name=dyson',
  '1926602169': 'AR/index.html?name=retroglasslamp',
};

export const ARIOSUrls = {
  '1172288913': 'AR/WalkerEdisonSideboard_iOS.usdz',
  '2795761532': 'AR/Oaklee6DrawerDresser_iOS.usdz',
  '5325854900': 'AR/GuyiCloudCoffeeTable_iOS.usdz',
  '532121642': 'AR/DecModeAccentTable_iOS.usdz',
  '2001343371': 'AR/ChesterfieldSofa_iOS.usdz',
  '384053648': 'AR/bigjoejoeybeanbagchair_iOS.usdz',
  '521928725': 'AR/mainstaysfauxfurplush_iOS.usdz',
  '5105358640': 'AR/urbanshoppurpleflowerdecorativepillow_iOS.usdz',
  '5261193145': 'AR/betterhomegardensmiraswivelchair_iOS.usdz',
  '42338382': 'AR/mainstaysrosette_iOS.usdz',
  '1670734640': 'AR/mainstays_iOS.usdz',
  '5113233021': 'AR/pacman_iOS.usdz',
  '674371865': 'AR/zenstyle_iOS.usdz',
  '5587806211': 'AR/durablemarsbookcase_iOS.usdz',
  '606463417': 'AR/mainstayssquare_iOS.usdz',
  '6053161268': 'AR/bigjoejoey_iOS.usdz',
  '5305297350': 'AR/urbanshopdiscoballdecorativepillow_iOS.usdz',
  '1528649331': 'AR/betterhomesgardenstabletoppictureframe_iOS.usdz',
  '3977610044': 'AR/dyson_iOS.usdz',
  '1926602169': 'AR/retroglasslamp_iOS.usdz',
};

export const ARAndoirdUrls = {
  '1172288913': 'AR/WalkerEdisonSideboard_Android.glb',
  '2795761532': 'AR/Oaklee6DrawerDresser_Android.glb',
  '5325854900': 'AR/GuyiCloudCoffeeTable_Android.glb',
  '532121642': 'AR/DecModeAccentTable_Android.glb',
  '2001343371': 'AR/ChesterfieldSofa_Android.glb',
  '384053648': 'AR/bigjoejoeybeanbagchair_Android.glb',
  '521928725': 'AR/mainstaysfauxfurplush_Android.glb',
  '5105358640': 'AR/urbanshoppurpleflowerdecorativepillow_Android.glb',
  '5261193145': 'AR/betterhomegardensmiraswivelchair_Android.glb',
  '42338382': 'AR/mainstaysrosette_Android.glb',
  '1670734640': 'AR/mainstays_Android.glb',
  '5113233021': 'AR/pacman_Android.glb',
  '674371865': 'AR/zenstyle_Android.glb',
  '5587806211': 'AR/durablemarsbookcase_Android.glb',
  '606463417': 'AR/mainstayssquare_Android.glb',
  '6053161268': 'AR/bigjoejoey_Android.glb',
  '5305297350': 'AR/urbanshopdiscoballdecorativepillow_Android.glb',
  '1528649331': 'AR/betterhomesgardenstabletoppictureframe_Android.glb',
  '3977610044': 'AR/dyson_Android.glb',
  '1926602169': 'AR/retroglasslamp_Android.glb',
};
