import React, { useEffect, useState } from 'react';
import { Box, Divider, Image, Skeleton, Text } from '@chakra-ui/react';
import axios from 'axios';

interface RecommendedProductsProps {
  variantId: string;
  openProductModal: (productId: string, fromRecommendation: boolean) => Promise<void>;
}

interface Product {
  id: string;
  title: string;
  price: number;
  media: {
    url: string;
    thumbnail_url: string;
    mobile_version_url: string;
  };
}

function RecommendedProducts({ variantId, openProductModal }: RecommendedProductsProps) {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchRecommendedProducts();
  }, [variantId]);

  const fetchRecommendedProducts = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/product/recommendation/${variantId}`)
      .then((response) => {
        setProducts(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  return (
    <Box w="100%" p="0" mt="8">
      <Divider />

      <Text
        fontSize={['18px', '18px']}
        fontFamily="Bogle-Bold"
        fontWeight="700"
        letterSpacing="-0.02em"
        color="#FFF"
        w="100%"
        mt="5"
        mb="5"
      >
        Recommended for you
      </Text>
      <Box
        w="100%"
        display="flex"
        flexDirection="row"
        flexWrap="nowrap"
        overflowX="auto"
        overflowY="hidden"
        scrollBehavior="smooth"
        style={{ scrollbarWidth: 'none' }}
        gap="3"
      >
        {loading ? (
          Array(3)
            .fill(null)
            .map((_, index) => <Skeleton borderRadius="16px" key={index} height="120px" width="120px" />)
        ) : !loading && products.length === 0 ? (
          <Text fontSize={['14px', '14px']} fontFamily="Bogle" fontWeight="700" letterSpacing="-0.02em" color="#FFF">
            No Recommended Products
          </Text>
        ) : (
          products.map((product: Product) => (
            <Box
              w="120px"
              key={product.id}
              flex={'1 0 120px'}
              cursor="pointer"
              onClick={() => openProductModal(product?.id?.toString(), true)}
            >
              <Image
                src={product.media.url}
                h="120px"
                w="120px"
                borderRadius="16px"
                objectFit="cover"
                alt="product thumbnail"
                loading="lazy"
              />

              <Text
                noOfLines={2}
                fontSize={['14px', '14px']}
                fontFamily="Bogle-Bold"
                fontWeight="700"
                letterSpacing="-0.02em"
                color="#FFF"
                mt="15px"
              >
                {product.title}
              </Text>

              <Text
                fontSize={['14px', '14px']}
                fontFamily="Bogle-Bold"
                fontWeight="700"
                letterSpacing="-0.02em"
                color="#FFF"
                mt="10px"
              >
                ${product.price.toFixed(2)}
              </Text>
            </Box>
          ))
        )}
      </Box>
    </Box>
  );
}

export default RecommendedProducts;
