import { CartItemProps } from './interfaces/components';

export interface CardClickObject {
  content_type: string;
  card_content: string;
}

export interface EcommerceObject {
  ecommerce: {
    value: number;
    currency: 'USD';
    items: EcommerceItem[];
  };
  product_source: 'direct' | 'recommended';
}

export interface EcommerceItem {
  item_id: string;
  item_name: string;
  affiliation: string;
  coupon: string;
  discount: number;
  index: number;
  item_brand: string;
  item_category: string | null;
  item_category2: string;
  item_category3: string;
  item_category4: string;
  item_category5: string;
  item_list_id: string;
  item_list_name: string;
  item_variant: string;
  location_id: string;
  price: number;
  quantity: number;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any[];
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function pushToAnalytics(object: any) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(object);
}

export const dispatchShareToAnalytics = (content_type: string, item_name: string) => {
  pushToAnalytics({
    event: 'share',
    content_type: content_type,
    item_name: item_name,
  });
};

export const dispatchViewARAnalytics = (product_id: string) => {
  pushToAnalytics({
    event: 'view_ar',
    item_name: product_id,
  });
};

export const dispatchViewCartToAnalytics = (ecommerceObject: EcommerceObject) => {
  window.dataLayer.push({ ecommerce: null });
  pushToAnalytics({
    event: 'view_cart',
    ...ecommerceObject,
  });
};

export const dispatchAddToCartToAnalytics = (ecommerceObject: EcommerceObject) => {
  window.dataLayer.push({ ecommerce: null });
  pushToAnalytics({
    event: 'add_to_cart',
    ...ecommerceObject,
  });
};

export const dispatchViewItemtoAnalytics = (ecommerceObject: EcommerceObject) => {
  window.dataLayer.push({ ecommerce: null });
  pushToAnalytics({
    event: 'view_item',
    ...ecommerceObject,
  });
};

export const dispatchPurchaseToAnalytics = (ecommerceObject: EcommerceObject) => {
  window.dataLayer.push({ ecommerce: null });
  pushToAnalytics({
    event: 'purchase',
    ...ecommerceObject,
  });
};

export const dispatchCardClickToAnalytics = (itemObject: CardClickObject) => {
  const obj = {
    ...itemObject,
    event: 'card_click',
  };
  pushToAnalytics(obj);
};

export const dispatchTurnTableInteractionToAnalytics = (ecommerceObject: EcommerceObject) => {
  window.dataLayer.push({ ecommerce: null });
  pushToAnalytics({
    event: 'turntable_interaction',
    ...ecommerceObject,
  });
};

export const retrieveEcommerceObjectFromWMCart = (): EcommerceObject => {
  const cart = localStorage.getItem('app_wm_cart');
  let ecommerceObject: EcommerceObject = {
    ecommerce: {
      value: 0,
      currency: 'USD',
      items: [],
    },
    product_source: 'direct',
  };
  if (cart == null) return ecommerceObject;
  const jsonCart = JSON.parse(cart);
  const ecommerceItems: EcommerceItem[] = [];
  let price = 0;
  jsonCart.map((rec: CartItemProps) => {
    ecommerceItems.push({
      item_id: rec.id,
      item_name: rec.name,
      affiliation: '',
      coupon: '',
      discount: 0,
      index: 0,
      item_brand: '',
      item_category: null,
      item_category2: '',
      item_category3: '',
      item_category4: '',
      item_category5: '',
      item_list_id: '',
      item_list_name: '',
      item_variant: '',
      location_id: '',
      price: rec.price,
      quantity: rec.quantity,
    });
    price += rec.price * rec.quantity;
  });
  ecommerceObject = {
    ecommerce: {
      value: price,
      currency: 'USD',
      items: ecommerceItems,
    },
    product_source: 'direct',
  };
  return ecommerceObject;
};

export const dispatchSelectedMusic = (music: string) => {
  pushToAnalytics({
    event: 'select_music',
    music_name: music,
  });
};

export const dispatchSelectedWalmartPlus = () => {
  pushToAnalytics({
    event: 'select_walmart_plus',
  });
};
