import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const Share = (props: IconProps) => (
  <Icon width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.5 8.25049H15.75C15.9489 8.25049 16.1397 8.32951 16.2803 8.47016C16.421 8.61081 16.5 8.80158 16.5 9.00049V18.0005C16.5 18.1994 16.421 18.3902 16.2803 18.5308C16.1397 18.6715 15.9489 18.7505 15.75 18.7505H2.25C2.05109 18.7505 1.86032 18.6715 1.71967 18.5308C1.57902 18.3902 1.5 18.1994 1.5 18.0005V9.00049C1.5 8.80158 1.57902 8.61081 1.71967 8.47016C1.86032 8.32951 2.05109 8.25049 2.25 8.25049H4.5M5.25 4.50049L9 0.750488M9 0.750488L12.75 4.50049M9 0.750488V11.2505"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
