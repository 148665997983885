import React from 'react';
import { Icon, IconProps } from '@chakra-ui/icons';

export const ThreeDView = (props: IconProps) => (
  <Icon width="173" height="102" viewBox="0 0 173 102" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_2796_4722)">
      <path d="M45.8 53.62C41.14 53.62 37.23 52.27 34.19 49.61C32.32 47.97 30.91 46 30.01 43.74C29.66 42.88 29.74 41.92 30.22 41.11C30.7 40.29 31.52 39.76 32.46 39.64L34.31 39.41C34.49 39.39 34.67 39.38 34.84 39.38C36.25 39.38 37.35 40.09 37.8 41.27C38.3 42.59 39.02 43.71 39.93 44.59C41.52 46.12 43.47 46.9 45.72 46.9C48.15 46.9 50.22 45.97 51.87 44.13C53.5 42.32 54.33 39.88 54.33 36.88C54.33 33.88 53.53 31.7 51.96 29.99C50.37 28.26 48.39 27.38 46.09 27.38H45.99C45.11 27.38 44.28 27 43.7 26.34C43.12 25.68 42.86 24.81 42.98 23.94C43.15 22.66 44.13 21.61 45.36 21.39C47.05 21.09 48.46 20.46 49.55 19.51C51.25 18.03 52.11 16.04 52.11 13.61C52.11 11.52 51.47 9.81998 50.19 8.54998C48.92 7.27998 47.23 6.62998 45.17 6.62998C43.11 6.62998 41.37 7.34998 39.93 8.75998C39.21 9.46998 38.63 10.34 38.2 11.35C37.69 12.56 36.57 13.34 35.35 13.34C35.19 13.34 35.02 13.33 34.86 13.3L33.24 13.02C32.34 12.87 31.57 12.32 31.13 11.53C30.67 10.72 30.61 9.74998 30.96 8.87998C31.41 7.74998 31.94 6.73998 32.52 5.88998C33.76 4.07998 35.53 2.62998 37.77 1.57998C40.04 0.519981 42.63 -0.0200195 45.47 -0.0200195C50.36 -0.0200195 54.16 1.47998 57.09 4.57998C59.48 7.08998 60.65 9.83998 60.65 12.97C60.65 16.52 59.12 19.43 55.97 21.85C55.24 22.41 54.87 23.29 54.97 24.21C55.07 25.13 55.62 25.91 56.45 26.29C58.04 27.03 59.4 28.11 60.51 29.49C62.23 31.63 63.1 34.26 63.1 37.31C63.1 41.85 61.48 45.62 58.16 48.82C54.87 51.99 50.72 53.6 45.81 53.6L45.8 53.62Z" />
      <path d="M85.54 53.62C80.3 53.62 76.11 51.64 72.74 47.57C69.37 43.5 67.66 36.65 67.66 27.2C67.66 17.75 69.44 10.46 72.96 6.26C76.49 2.05 80.94 0 86.58 0C90.46 0 93.7 1.09 96.21 3.24C97.28 4.16 98.2 5.27 98.94 6.54C99.59 7.65 99.62 8.96 99.03 10.13C98.43 11.33 97.27 12.13 95.95 12.28C95.79 12.3 95.64 12.31 95.48 12.31C93.95 12.31 92.58 11.5 91.98 10.25C91.63 9.52 91.19 8.9 90.66 8.4C89.44 7.24 87.86 6.65 85.95 6.65C83.44 6.65 81.31 7.78 79.6 10C78.19 11.84 77.26 15.15 76.75 20.11C76.69 20.68 76.87 21.22 77.24 21.63C77.62 22.05 78.15 22.28 78.71 22.28C79.17 22.28 79.61 22.12 79.97 21.82C82.04 20.12 84.39 19.3 87.17 19.3C91.28 19.3 94.71 20.83 97.65 23.97C100.57 27.09 102.05 31.18 102.05 36.15C102.05 41.43 100.49 45.71 97.42 48.87C94.36 52.02 90.36 53.62 85.53 53.62H85.54ZM85.54 25.05C83.26 25.05 81.3 25.95 79.73 27.73C78.19 29.47 77.41 32.03 77.41 35.33C77.41 38.94 78.27 41.79 79.98 43.8C81.72 45.86 83.77 46.91 86.06 46.91C88.35 46.91 90.15 46.03 91.62 44.31C93.07 42.6 93.78 39.97 93.78 36.26C93.78 32.55 93 29.68 91.48 27.86C89.91 25.99 87.91 25.05 85.55 25.05H85.54Z" />
      <path d="M123.69 53.62C118.66 53.62 114.72 51.74 111.64 47.88C108.55 44.01 106.99 36.89 106.99 26.72C106.99 16.55 108.72 9.51 112.13 5.2C114.91 1.7 118.69 0 123.69 0C128.69 0 132.49 1.72 135.29 5.27C138.67 9.53 140.39 16.78 140.39 26.83C140.39 36.88 138.66 44.11 135.25 48.42C132.47 51.92 128.69 53.62 123.69 53.62ZM123.69 6.72C122.21 6.72 120.87 7.2 119.7 8.14C118.54 9.09 117.68 10.67 117.07 12.97C116.33 15.77 115.96 20.3 115.96 26.83C115.96 33.36 116.29 37.75 116.96 40.24C117.66 42.85 118.57 44.58 119.71 45.51C120.86 46.44 122.19 46.91 123.68 46.91C125.17 46.91 126.5 46.43 127.67 45.49C128.83 44.54 129.69 42.96 130.3 40.66C131.04 37.89 131.41 33.36 131.41 26.83C131.41 20.3 131.08 15.92 130.41 13.42C129.71 10.81 128.81 9.08 127.66 8.14C126.51 7.19 125.17 6.71 123.69 6.71V6.72Z" />
      <path d="M145.43 9.88C145.43 4.04 149.65 0 155.78 0C161.91 0 166.18 4.05 166.18 9.88C166.18 15.71 161.96 19.82 155.78 19.82C149.6 19.82 145.43 15.77 145.43 9.88ZM161.21 9.88C161.21 6.76 158.96 4.5 155.78 4.5C152.6 4.5 150.41 6.75 150.41 9.88C150.41 13.01 152.66 15.26 155.78 15.26C158.9 15.26 161.21 13.06 161.21 9.88Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M158.506 40.0128C159.946 38.3374 162.472 38.1465 164.147 39.5864C168.977 43.7376 172.28 48.8592 172.28 54.7799C172.28 61.7819 167.69 67.6465 161.308 72.199C154.842 76.8104 145.829 80.6227 135.25 83.4723C133.117 84.0469 130.922 82.7835 130.348 80.6504C129.773 78.5173 131.036 76.3222 133.17 75.7476C143.231 73.0372 151.253 69.5444 156.662 65.6859C162.155 61.7684 164.28 57.998 164.28 54.7799C164.28 52.0607 162.783 48.9623 158.933 45.6535C157.257 44.2136 157.066 41.6881 158.506 40.0128Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1686 38.5186C17.4728 40.3017 17.0845 42.8045 15.3014 44.1086C13.1211 45.7033 11.4553 47.3099 10.2704 48.8728C8.67702 50.9851 8.01001 52.9492 8.01001 54.78C8.01001 57.4435 9.44388 60.4669 13.1091 63.6976C16.7683 66.9228 22.2926 70.0099 29.4635 72.6833C43.7752 78.0187 63.8154 81.4 86.15 81.4C95.631 81.4 104.715 80.7907 113.134 79.6747C115.324 79.3844 117.335 80.9244 117.625 83.1144C117.916 85.3044 116.376 87.315 114.186 87.6053C105.405 88.7693 95.969 89.4 86.15 89.4C63.1146 89.4 42.0848 85.9264 26.669 80.1793C18.9762 77.3114 12.4724 73.8004 7.81931 69.699C3.17239 65.6031 0.0100098 60.5716 0.0100098 54.78C0.0100098 50.8518 1.48234 47.2369 3.88725 44.0504L3.89187 44.0443L3.89188 44.0443C5.6469 41.7281 7.92034 39.5957 10.5786 37.6514C12.3617 36.3473 14.8644 36.7355 16.1686 38.5186Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.9216 64.7515C100.484 63.1894 103.016 63.1894 104.578 64.7515L119.728 79.9015C120.479 80.6517 120.9 81.6691 120.9 82.73C120.9 83.7908 120.479 84.8082 119.728 85.5584L104.578 100.708C103.016 102.27 100.484 102.27 98.9216 100.708C97.3595 99.1463 97.3595 96.6136 98.9216 95.0515L111.243 82.73L98.9216 70.4084C97.3595 68.8463 97.3595 66.3136 98.9216 64.7515Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_2796_4722">
        <rect width="172.28" height="101.88" />
      </clipPath>
    </defs>
  </Icon>
);
