import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Text,
  Image,
  Link,
} from '@chakra-ui/react';
import { Close } from '../../Icons/Close';
import Emptycart from '../../assets/images/empty cart.webp';
import WaltmartPlusLogo from '../../assets/images/walmartplus-logo.png';
import { CartItemProps, CommonProps } from '../../interfaces/components';
import CartItem from './CartItem';
import React, { useEffect, useState } from 'react';
import { dispatchPurchaseToAnalytics, retrieveEcommerceObjectFromWMCart } from '../../analytics';
import useWindowDimensions from '../../utils/useWindowDimensions';

interface CartDrawerProps extends CommonProps {
  data: CartItemProps[];
  setCartItems: (data: CartItemProps[]) => void;
}

function CartDrawer({ data, active, close, setCartItems }: CartDrawerProps) {
  const { height } = useWindowDimensions();
  const [cartSubtotal, setCartSubtotal] = useState<number>(0);

  const onItemRemove = (id: string) => {
    const cart = localStorage.getItem('app_wm_cart');
    if (cart !== null) {
      const _newCart = JSON.parse(cart).filter((rec: CartItemProps) => rec.id !== id);
      localStorage.setItem('app_wm_cart', JSON.stringify(_newCart));
      setCartItems(_newCart);
    }
  };

  const checkout = () => {
    const baseURL = `https://goto.walmart.com/c/5371324/568844/9383?veh=aff&sourceid=imp_000011112222333344&u=https%3A%2F%2Faffil.walmart.com%2Fcart%2FaddToCart%3Fitems%3D`;
    const cart = localStorage.getItem('app_wm_cart');
    if (cart !== null) {
      dispatchPurchaseToAnalytics(retrieveEcommerceObjectFromWMCart());
      const idsWithQuantity = JSON.parse(cart).map((rec: CartItemProps) => rec.id + '_' + rec.quantity);
      const idsWithQuantityWithCommas = idsWithQuantity.join(',');
      const finalUrl = baseURL + idsWithQuantityWithCommas;
      window.open(finalUrl, '_blank');
    }
  };

  const updateCart = (id: string, newQuantity: number) => {
    const existingCart = localStorage.getItem('app_wm_cart') || '';

    if (existingCart?.length > 0 && existingCart) {
      const cart = JSON.parse(existingCart);
      const newCart = cart.map((cartItem: CartItemProps) =>
        cartItem.id === id ? { ...cartItem, quantity: newQuantity } : cartItem,
      );
      setCartItems(newCart);
      localStorage.setItem('app_wm_cart', JSON.stringify(newCart));
    }
  };

  useEffect(() => {
    if (data && data.length > 0) {
      const subTotal = data.reduce((total, item) => total + Number((item.price * item.quantity).toFixed(2)), 0);
      setCartSubtotal(subTotal);
    }
  }, [data]);

  return (
    <Drawer isOpen={active} placement="right" onClose={close} size={['full', 'sm', 'sm', 'sm', 'sm']} autoFocus={false}>
      <DrawerOverlay />
      <DrawerContent maxH={height}>
        <Box
          boxSize={8}
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex="100"
          position="absolute"
          top="10px"
          right="10px"
          cursor="pointer"
          onClick={close}
        >
          <Close stroke="black" boxSize={[4, 4, 4]} />
        </Box>

        <Box w="100%" h="122px" background="#FFC220" mt="50px" position="relative" overflow="hidden">
          <Box
            background="#F6FAFC"
            borderRadius="8px"
            h="114px"
            m="4px 6px"
            pl="80px"
            display="flex"
            alignItems="center"
          >
            <Box display="flex" flexDir="column" gap="2">
              <Text
                fontSize="12px"
                lineHeight="15.6px"
                fontFamily="Bogle-Medium"
                fontWeight="500"
                letterSpacing="-0.02em"
                textAlign="left"
                color="#0071CE"
              >
                College students get
              </Text>

              <Text
                fontSize="18px"
                lineHeight="16.2px"
                fontFamily="Bogle-Bold"
                fontWeight="700"
                letterSpacing="-0.05em"
                textAlign="left"
                color="#0071CE"
              >
                50% OFF a Walmart+ membership
              </Text>

              <Text
                fontSize="12px"
                lineHeight="15.6px"
                fontFamily="Bogle-Medium"
                fontWeight="500"
                letterSpacing="-0.02em"
                textAlign="left"
                color="#0071CE"
              >
                for free delivery, video streaming & so much more!
                <br />
                Terms apply. Learn more at{' '}
                <Link target="_blank" href="https://walmart.com/student" textDecoration={'underline'}>
                  walmart.com/student
                </Link>
              </Text>
            </Box>

            <Image
              src={WaltmartPlusLogo}
              alt="walmart plus logo"
              w="71.27px"
              h="24px"
              position="absolute"
              top="15px"
              right="15px"
              loading="lazy"
            />
          </Box>

          <Box
            background="#FFC220"
            w="126px"
            h="178px"
            position="absolute"
            top="-32px"
            left="-63px"
            borderRadius="70%"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Text
              fontSize="12px"
              lineHeight="14.4px"
              fontFamily="Bogle-Bold"
              fontWeight="700"
              letterSpacing="-0.02em"
              textAlign="left"
              color="#041E42"
              w="50px"
            >
              Limited Time
            </Text>
          </Box>
        </Box>

        <DrawerBody p={['20px', '20px', '40px']}>
          {data.length == 0 ? (
            <Box
              w="100%"
              h="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              gap={10}
            >
              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Image
                  src={Emptycart}
                  width={['100px', '100px', '150px']}
                  height="auto"
                  alt="empty cart"
                  loading="lazy"
                />
                <Text
                  fontSize={['20px', '20px', '24px']}
                  fontFamily="Bogle"
                  fontWeight="700"
                  letterSpacing="-0.02em"
                  textAlign="center"
                >
                  {`Nothing to see here.`}
                </Text>
                <Text
                  fontSize={['20px', '20px', '24px']}
                  fontFamily="Bogle"
                  fontWeight="700"
                  letterSpacing="-0.02em"
                  textAlign="center"
                >
                  {`Let's fix that`}
                </Text>
              </Box>

              <Button
                variant="solid"
                color="white"
                padding={['12px']}
                borderRadius="40px"
                background="radial-gradient(100% 131.41% at 100% 100%, #1A97FF 0%, #004F9A 100%);"
                border="1px solid #FFFFFF4D"
                fontSize="18px"
                fontWeight="700"
                fontFamily="Bogle-Bold"
                _hover={{ background: 'radial-gradient(100% 131.41% at 100% 100%, #007CF2 0%, #012D58 100%)' }}
                _active={{ background: 'radial-gradient(100% 131.41% at 100% 100%, #0255A6 0%, #041E40 100%)' }}
                _focus={{ background: 'radial-gradient(100% 131.41% at 100% 100%, #0255A6 0%, #041E40 100%)' }}
                cursor="pointer"
                minW="240px"
                height="50px"
                pointerEvents="auto"
                onClick={close}
              >
                Continue shopping
              </Button>
            </Box>
          ) : (
            <>
              <Text
                fontSize={['32px', '32px']}
                lineHeight={['42px', '42px']}
                fontFamily="Bogle"
                fontWeight="700"
                letterSpacing="-0.02em"
                textAlign="left"
                mt="1"
              >
                Your cart
              </Text>

              <Box w="100%" h="auto" overflowY="auto" overflowX="hidden">
                {data.map((item) => (
                  <CartItem
                    {...item}
                    key={`${item.id}`}
                    id={item.id}
                    name={item.name}
                    imageSrc={item.imageSrc}
                    price={item.price}
                    quantity={item.quantity}
                    onItemRemove={() => onItemRemove(item.id)}
                    updateCart={updateCart}
                  />
                ))}
              </Box>
            </>
          )}
        </DrawerBody>
        {data.length > 0 && (
          <DrawerFooter p={['16px 20px 32px 20px']} pt={'0px'} bg="#E6F1FC" display="flex" flexDir="column" gap="3">
            <Box display="flex" justifyContent="space-between" w="100%" minH="40px">
              <Text fontSize={['18px', '18px']} fontFamily="Bogle" fontWeight="700" letterSpacing="-0.02em">
                Subtotal
              </Text>
              <Text fontSize={['18px', '18px']} fontFamily="Bogle" fontWeight="700" letterSpacing="-0.02em">
                ${cartSubtotal.toFixed(2)}
              </Text>
            </Box>
            <Button
              variant="solid"
              color="white"
              padding={['16px 32px']}
              borderRadius="40px"
              background="radial-gradient(100% 131.41% at 100% 100%, #1A97FF 0%, #004F9A 100%);"
              border="1px solid #FFFFFF4D"
              fontSize="14px"
              fontWeight="700"
              fontFamily="Bogle-Bold"
              _hover={{ background: 'radial-gradient(100% 131.41% at 100% 100%, #007CF2 0%, #012D58 100%)' }}
              _active={{ background: 'radial-gradient(100% 131.41% at 100% 100%, #0255A6 0%, #041E40 100%)' }}
              _focus={{ background: 'radial-gradient(100% 131.41% at 100% 100%, #0255A6 0%, #041E40 100%)' }}
              transition="all 0.2s ease-in-out"
              cursor="pointer"
              w="100%"
              h="45px"
              onClick={checkout}
            >
              <Text
                fontSize={['18px', '18px']}
                lineHeight={['24px', '24px']}
                fontFamily="Bogle"
                fontWeight="700"
                letterSpacing="-0.02em"
              >
                Checkout on Walmart.com
              </Text>
            </Button>
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  );
}

export default CartDrawer;
